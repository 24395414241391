<template>
  <b-card class="product-add-wrapper">
    <validation-observer ref="addCustomerFlowValidation">
      <!-- form -->
      <b-form>
        <b-row>
          <!-- Sur name -->
          <b-col md="12">
            <b-form-group
              label="Sur name"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.surname"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Guest id -->
          <b-col md="6">
            <b-form-group
              label="Guest id"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.guestId"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Res code -->
          <b-col md="6">
            <b-form-group
              label="Res code"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.resCode"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Pax -->
          <b-col md="6">
            <b-form-group
              label="Pax"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.pax"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Email -->
          <b-col md="6">
            <b-form-group
              label="Email"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.email"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Adults -->
          <b-col md="6">
            <b-form-group
              label="Adults"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.adults"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Children -->
          <b-col md="6">
            <b-form-group
              label="Children"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.children"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Room -->
          <b-col md="6">
            <b-form-group
              label="Room"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.room"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Room type -->
          <b-col md="6">
            <b-form-group
              label="Room type"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.roomType"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Arrival Date -->
          <b-col md="6">
            <b-form-group
              label="Arrival Date"
              class="mb-2"
            >
              <b-form-datepicker
                v-model="customerFlow.arrivalDate"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Departure Date -->
          <b-col md="6">
            <b-form-group
              label="Departure Date"
              class="mb-2"
            >
              <b-form-datepicker
                v-model="customerFlow.departureDate"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Check in time -->
          <b-col md="6">
            <b-form-group
              label="Check in time"
              class="mb-2"
            >
              <b-form-datepicker
                v-model="customerFlow.checkInTime"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Check out time -->
          <b-col md="6">
            <b-form-group
              label="Check out time"
              class="mb-2"
            >
              <b-form-datepicker
                v-model="customerFlow.checkOutTime"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Note -->
          <b-col md="6">
            <b-form-group
              label="Check in note"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.note"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Checkout note -->
          <b-col md="6">
            <b-form-group
              label="Checkout note"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.checkOutNote"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- Feed back -->
          <b-col md="12">
            <b-form-group
              label="Feed back"
              class="mb-2"
            >
              <b-form-input
                v-model="customerFlow.feedback"
                readonly
              />
            </b-form-group>
          </b-col>
          <div
            v-if="ratingList.length"
            style="width: 100%;"
          >
            <b-col
              v-for="rate in ratingList"
              :key="rate.id"
              md="12"
            >
              <b-form-group
                :label="mapTitleRating(rate.ratingId)"
                class="mb-2"
              >
                <b-form-rating
                  v-model="rate.rating"
                  variant="warning"
                  stars="5"
                  readonly
                />
              </b-form-group>

            </b-col>
          </div>
          <!-- Overall Rating -->
          <b-col md="12">
            <b-form-group
              label="Overall rating"
              class="mb-2"
            >
              <b-form-rating
                v-model="customerFlow.overallRating"
                variant="warning"
                stars="10"
                readonly
              />
            </b-form-group>
          </b-col>
          <!-- <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Featured Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <vue-upload-multiple-image
                    browse-text="Or choose"
                    drag-text="Drag image (multiple)"
                    drop-text="Drop your images"
                    :show-primary="false"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                  />
                </b-media-aside>
                <b-media-body>
                  <div class="mt-5 ml-2">
                    <small class="text-muted">Required image resolution 850x225, image size 5mb.</small>
                    <b-card-text class="my-50">
                      {{ 'banner.jpg' }}
                    </b-card-text>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col> -->
          <b-col cols="12">
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="add"
            >
              Update
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :to="{ name: 'customer-flow' }"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </validation-observer>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BFormDatepicker,
  BFormRating,
} from 'bootstrap-vue'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import addCustomerFlow from './updateCustomerFlow'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    VueUploadMultipleImage,
    BFormDatepicker,
    BFormRating,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      numberDefault: null,
      snowOption: {
        placeholder: '',
        theme: 'snow',
      },
      required,
      email,
    }
  },
  computed: {
    ...mapGetters('app', ['lanCodeOptions', 'statusOptions']),
  },
  setup() {
    const {
      customerFlow,
      imageIDs,
      arrivalDate,
      departureDate,
      ratingList,
      ratingOptions,
    } = addCustomerFlow()
    return {
      imageIDs,
      customerFlow,
      arrivalDate,
      departureDate,
      ratingList,
      ratingOptions,
    }
  },
  methods: {
    mapTitleRating(idRating) {
      return this.ratingOptions.find(x => x.id === idRating).title
    },
    uploadImageSuccess(formData, index, fileList) {
      formData.append('name', 'hehe')
      store.dispatch('program/uploadImage', formData).then(response => {
        this.imageIDs.push(response.body.id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload image success',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.error,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },
    beforeRemove(index, done, fileList) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this image.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.imageIDs.splice(index, 1)
            done()
          }
        })
    },
    editImage(formData, index, fileList) {
      this.imageIDs.splice(index, 1)
      formData.append('name', 'hehe')
      store.dispatch('program/uploadImage', formData).then(response => {
        this.imageIDs.push(response.body.id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload image success',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.error,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },
    add() {
      this.$refs.addCustomerFlowValidation.validate().then(success => {
        if (success) {
          const payload = {
            ...this.customerFlow,
            arrivalDate: new Date(this.arrivalDate),
            departureDate: new Date(this.departureDate),
            identityPapers: this.imageIDs.map(x => ({
              media_id: x,
            })),
          }
          store.dispatch('customerFlow/updateCustomerFlow', payload).then(response => {
            if (response.request.status === 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Create customer successfuly',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              router.push('/customer-flow')
            }
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error}`,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

// @import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
    display: none;
}
</style>
