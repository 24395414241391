import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default function addCustomerFlow() {
  const toast = useToast()
  const customerFlow = ref({})
  const imageIDs = ref([])
  const arrivalDate = ref(null)
  const departureDate = ref(null)
  const ratingList = ref([])
  const ratingOptions = ref([])
  store.dispatch('rating/fetchRatings', {
    take: 50,
    page: 1,
  }).then(res => {
    ratingOptions.value = res.body.data
  })
  store.dispatch('customerFlow/fetchCustomerFlow', { id: router.currentRoute.params.id }).then(res => {
    customerFlow.value = res.body
    departureDate.value = res.body.departureDate
    arrivalDate.value = res.body.arrivalDate
  }).catch(e => {
    console.log(e)
    toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching customer',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  })
  store.dispatch('customerFlow/fetchCustomerRating', { id: router.currentRoute.params.id }).then(res => {
    ratingList.value = res.body
  }).catch(e => {
    console.log(e)
    toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching customer rating',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  })
  return {
    customerFlow,
    imageIDs,
    arrivalDate,
    departureDate,
    ratingList,
    ratingOptions,
  }
}
